.copy-container {
  position: relative;
}
.copy-text {
  position: relative;
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
}
.copy-text input.text {
  padding: 10px;
  font-size: 18px;
  color: #555;
  border: none;
  width: calc(100% - 80px);
  outline: none;
}
.copy-text button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px;

  background: #5784f5;
  color: #fff;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}

.copy-text button:active {
  background: #809ce2;
}

.tooltip {
  position: absolute;
  top: -45px;
  right: 25px;
  background: #5784f5; /* Match the copy button color */
  padding: 8px 10px;
  border-radius: 10px;
  font-size: 15px;
  color: #fff;
  display: flex;
  align-items: center;
}

.copy-text button:before {
  content: "Copied";
  position: absolute;
  top: -45px;
  right: 0;
  background: #5c81dc;
  padding: 8px 10px;
  border-radius: 20px;
  font-size: 15px;
  color: #fff;
  display: none;
  z-index: 1;
}

.copy-text button:after {
  content: "";
  position: absolute;
  top: -20px;
  right: 25px;
  width: 10px;
  height: 10px;
  background: #5c81dc;
  transform: rotate(45deg);
  display: none;
}

.copy-text.active button:before,
.copy-text.active button:after {
  display: block;
}
