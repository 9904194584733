@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

#root {
  font-family: "Poppins", sans-serif;
}

.input-box-shadow {
  filter: drop-shadow(-4px -4px 44px rgba(0, 0, 0, 0.08));
}

.slick-prev:before,
.slick-next:before {
  color: #000;
  font-size: 40px;
}

.slick-next:before {
  position: absolute;
  right: 0;
  margin-top: -10px;
  margin-right: 5px;
}

.slick-next,
.slick-prev {
  position: absolute;
  right: 0;
}

.slick-prev {
  z-index: 99999;
  margin-left: 25px;
}

.category-card:hover .arrow-icon {
  fill: white;
}

.slick-arrow {
  position: absolute;
  right: 50;
  margin-right: 25px;
}

.slick-dots {
  @apply ml-[-10px] hidden;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px; 
  right: 50%; 
  background-color: #20b486;
  color: white;
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scroll-to-top:hover {
  background-color: #1a906b;
}

@media screen and (min-width: 1024px) and (max-height: 1310px) {
  .qr {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: none;
    color: white;
    padding: 9px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0px 5px 15px #20b486;
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 25px;
    margin-bottom: 50px;
    margin-right: 20px;
  }
}
.qr-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

p {
  margin-top: 25px;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  color: #000;
  font: 400 1rem/1.75rem "Open Sans", sans-serif;
}

