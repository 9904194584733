.dark-mode {
  background-color: #363537;
  color: #fff;
}

.dark-mode .Vbfug a {
  color: #a8c8fb;
}

.dark-mode .UIuSk {
  color: #c5c7c6;
}

.dark-mode .TT9eCd {
  color: #fff;
}

.dark-mode .ClM7O{
    color : #fff;
}

.dark-mode .g1rdde {
  color: #c5c7c6;
}

.dark-mode .xFVDSb{
    color : #fff;
}

.dark-mode .bARER{
    color : rgb(197, 199, 198);
}

.dark-mode .pSEeg{
    color : rgb(197, 199, 198);
}

.dark-mode .XfZNbf{
    color : #fff;
}

/* Share button styles */
.app-details .VfPpkd-LgbsSe {
  color: #a8c8fb;
}

.app-details .VfPpkd-LgbsSe:hover {
  background-color: #a8c8fb;
  color: #fff;
}