.text-red-500 {
  font-weight: 800;
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
@media screen and (max-width: 640px) {
  .text-red-500 {
    font-weight: 800;
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity));
  }
  h1 {
    margin-top: 40px;
    color: #fff;
  }
  .label-text {
    color: #000;
    font-weight: 2rem;
  }
  .text-3xl {
    font-size: 1.3rem;
    line-height: 2.25rem;
  }
  .ml-\[16\.5\%\] {
    margin-left: 0.5%;
  }
  .text-blue-700 {
    color: #fff;
    background-color: rgb(29 78 216 / var(--tw-text-opacity));
  }
  .dark\:border-blue-500 {
    border: 2px solid;
    --tw-border-opacity: 1;
    border-color: rgb(29 78 216 / var(--tw-text-opacity));
  }
  .text-blue-700:hover {
    font-weight: 800;
    color: rgb(29 78 216 / var(--tw-text-opacity));
    background-color: #fff;
  }
}
