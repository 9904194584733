body {
  background: #fff;
}

#loading-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1001;
  width: 80px;
  height: 80px;
  margin: -40px 0 0 -40px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: red;
  -webkit-animation: PreloaderSpin 2s linear infinite;
  animation: PreloaderSpin 2s linear infinite;
}
#loading-spinner:before {
  content: "";
  display: block;
  width: 60px;
  height: 60px;
  margin: 8px 0 0px 8px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #000;
  -webkit-animation: PreloaderSpin 6s linear infinite;
  animation: PreloaderSpin 6s linear infinite;
}
#loading-spinner:after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  margin: -52px 0 0px 18px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: yellow;
  -webkit-animation: PreloaderSpin 3s linear infinite;
  animation: PreloaderSpin 3s linear infinite;
}

@keyframes PreloaderSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
