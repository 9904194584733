.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dim the background */
  z-index: 1000;
}

.box-container {
  display: flex;
  height: 100vh;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  padding: 20px;
  position: relative; /* Added to make .close-button-container positioning relative to this container */
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.close-icon {

  font-size: 24px;
  color: #000; /* Adjust the color as needed */
}

.sub-box-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  max-width: 700px;
  width: 100%;
  background: #fff;
  padding: 25px 30px;
  border-radius: 5px;
}

.copy-container {
  position: relative;
}
.copy-text {
	position: relative;
	padding: 10px;
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 10px;
	display: flex;
}
.copy-text input.text {
	padding: 10px;
	font-size: 18px;
	color: #555;
	border: none;
    width: calc(100% - 80px);
	outline: none;
}
.copy-text button {
    display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
	padding: 10px;
    
	background: #20B486;
	color: #fff;
	font-size: 18px;
	border: none;
	outline: none;
	border-radius: 10px;
	cursor: pointer;
}

.copy-text button:active {
	background: #20B486;
}

.tooltip {
  position: absolute;
  top: -45px;
  right: 25px;
  background: #20B486; /* Match the copy button color */
  padding: 8px 10px;
  border-radius: 10px;
  font-size: 15px;
  color: #fff;
  display: flex;
  align-items: center; /* Align text vertically in the tooltip */
}


.copy-text button:before {
  content: "Copied";
  position: absolute;
  top: -45px;
  right: 0;
  background: #20B486;
  padding: 8px 10px;
  border-radius: 20px;
  font-size: 15px;
  color: #fff;
  display: none;
  z-index: 1; /* Ensure the tooltip is above the button */
}

.copy-text button:after {
  content: "";
  position: absolute;
  top: -20px;
  right: 25px;
  width: 10px;
  height: 10px;
  background: #20B486;
  transform: rotate(45deg);
  display: none;
}

.copy-text.active button:before,
.copy-text.active button:after {
  display: block;
}

@media only screen and (max-width: 768px) {
  .container {
    position: relative;

    margin-top: 30%;
    left: 50%;
  }
  .box-container {
    max-width: 100%;
  }

}

@media only screen and (max-width: 584px) {
  .container {
    position: relative;

    margin-top: 30%;
    left: 50%;
  }
  .box-container {
    max-width: 100%;
  }

}

@media only screen and (max-width: 425px) {
  .container {
    position: relative;

    margin-top: 40%;
    left: 50%;
  }
  .box-container {
    max-width: 100%;
  }

}

.VfPpkd-cnG4Wd {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-body1-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 1rem;
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  -webkit-text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
}

.VfPpkd-cnG4Wd {
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  overflow: auto;
}

.VfPpkd-cnG4Wd {
  color: rgba(0, 0, 0, 0.6);
}

.VfPpkd-cnG4Wd {
  padding: 20px 24px 20px 24px;
}

.VfPpkd-cnG4Wd {
  color: #5f6368;
}

.VfPpkd-cnG4Wd {
  font-family: Roboto, Arial, sans-serif;
  line-height: 1.25rem;
  font-size: 0.875rem;
  letter-spacing: 0.0142857143em;
  font-weight: 400;
}

.VfPpkd-cnG4Wd {
  padding: 24px 24px 18px 24px;
}

.LUq2je {
  display: flex;
  flex-shrink: 0;
}

.VfPpkd-cnG4Wd > :first-child {
  margin-top: 0;
}

.Gqr3Sb {
  --gap-width: 12px;
  margin: 12px -16px 0;
  overflow: visible;
  padding: 0 16px;
}

.VfPpkd-cnG4Wd > :last-child {
  margin-bottom: 0;
}

.efXJRc {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd {
  overflow: visible;
}

.VfPpkd-Bz112c-LgbsSe {
  font-size: 24px;
  width: 48px;
  height: 48px;
  padding: 12px;
}

.VfPpkd-Bz112c-LgbsSe {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  z-index: 0;
  overflow: visible;
}

.VfPpkd-Bz112c-LgbsSe {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.yHy1rc {
  z-index: 0;
}

.EnisIf {
  margin: -12px -12px 0 0;
  min-width: 48px;
}

.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.VfPpkd-ksKsZd-XxIAqe[data-mdc-ripple-is-unbounded]:before,
.VfPpkd-ksKsZd-XxIAqe[data-mdc-ripple-is-unbounded]:after,
.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd:before,
.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd:after {
  top: calc(50% - 50%);
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.VfPpkd-ksKsZd-XxIAqe[data-mdc-ripple-is-unbounded].VfPpkd-ksKsZd-mWPk3d:before,
.VfPpkd-ksKsZd-XxIAqe[data-mdc-ripple-is-unbounded].VfPpkd-ksKsZd-mWPk3d:after,
.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd.VfPpkd-ksKsZd-mWPk3d:before,
.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd.VfPpkd-ksKsZd-mWPk3d:after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.VfPpkd-ksKsZd-XxIAqe[data-mdc-ripple-is-unbounded].VfPpkd-ksKsZd-mWPk3d:after,
.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd.VfPpkd-ksKsZd-mWPk3d:after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.bewvKb {
  position: relative;
}

.fmiEqb {
  display: none;
  margin: 10px 0;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .fmiEqb {
    display: inline-flex;
  }
}

.Q7vmV {
  align-items: center;
  margin-top: 8px;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .Q7vmV {
    display: none;
  }
}

img {
  border: none;
}

img {
  border: 0;
  margin: 0;
  padding: 0;
}

img {
  vertical-align: middle;
}

img {
  border: 0;
  color: inherit;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

.T75of {
  background-size: contain;
}

.PT2eod {
  border-radius: 8px;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.09), 0 3px 5px rgba(0, 0, 0, 0.06),
    0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
  height: 48px;
  margin-right: 16px;
  width: 48px;

}

@media screen and (min-width: 1280px) {
  .PT2eod {
    height: 56px;
    width: 56px;
  }
}

.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  z-index: var(--mdc-ripple-z-index, 1);
}

.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::after {
  background-color: #000;
  background-color: var(--mdc-ripple-color, #000);
}

.yHy1rc .VfPpkd-Bz112c-Jh9lGc::before,
.yHy1rc .VfPpkd-Bz112c-Jh9lGc::after {
  z-index: -1;
}

.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Bz112c-Jh9lGc::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Bz112c-Jh9lGc::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.VfPpkd-Bz112c-LgbsSe .VfPpkd-Bz112c-Jh9lGc::after {
  z-index: 0;
  z-index: var(--mdc-ripple-z-index, 0);
}

.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Bz112c-Jh9lGc::after {
  top: 0;
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d-OWXEXe-ZNMTqd
  .VfPpkd-Bz112c-Jh9lGc::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}

.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-mWPk3d .VfPpkd-Bz112c-Jh9lGc::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.VfPpkd-Bz112c-LgbsSe:hover .VfPpkd-Bz112c-Jh9lGc::before,
.VfPpkd-Bz112c-LgbsSe.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE
  .VfPpkd-Bz112c-Jh9lGc::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.VfPpkd-Bz112c-J1Ukfc-LhBDec {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  display: none;
}

.mN1ivc .VfPpkd-Bz112c-J1Ukfc-LhBDec {
  pointer-events: none;
  border: 2px solid rgb(24, 90, 188);
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}

.VfPpkd-Bz112c-J1Ukfc-LhBDec:after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}

.mN1ivc .VfPpkd-Bz112c-J1Ukfc-LhBDec::after {
  content: "";
  border: 2px solid rgb(232, 240, 254);
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}

i {
  border: 0;
  color: inherit;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

.google-material-icons {
  font-family: "Google Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.aoJE7e {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.yUFvre {
  gap: var(--gap-width);
  padding: calc(var(--gap-width) / 2) 0;
  padding-left: _get-adjusted-space("default");
  scroll-snap-type: unset;
}

.aoJE7e:-webkit-scrollbar {
  display: none;
}

.Ufn6O {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.J4Vmsd {
  width: 100%;
}

.sdjgmb {
  width: 100%;
}

h5 {
  border: 0;
  margin: 0;
  padding: 0;
}

h5 {
  border: 0;
  color: inherit;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

.ksLHF {
  color: rgb(32, 33, 36);
  fill: rgb(32, 33, 36);
  stop-color: rgb(32, 33, 36);
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  line-height: 1.75rem;
  font-size: 1.375rem;
  letter-spacing: 0;
  font-weight: 500;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
}

.ksLHF:not(:disabled) {
  color: rgb(32, 33, 36);
}

.cYHJbc {
  color: rgb(95, 99, 104);
  fill: rgb(95, 99, 104);
  stop-color: rgb(95, 99, 104);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.75rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
}

.cYHJbc:not(:disabled) {
  color: rgb(95, 99, 104);
}

.ULeU3b {
  flex-shrink: 0;
  scroll-snap-align: start;
}

.nL1Pwe {
  cursor: pointer;
  padding: 10px 2px;
  text-align: center;
}

@media screen and (min-width: 600px) {
  .nL1Pwe {
    padding: 10px;
  }
}

.nL1Pwe:first-child {
  margin-left: 1px;
}

.nL1Pwe:last-child {
  margin-right: 1px;
}

label {
  border: 0;
  color: inherit;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

.VfPpkd-fmcmS-yrriRe {
  border-top-left-radius: 4px;
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 4px;
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  display: inline-flex;
  align-items: baseline;
  padding: 0 16px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  will-change: opacity, transform, color;
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc {
  height: 56px;
  overflow: visible;
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc {
  padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc {
  padding-right: max(16px, var(--mdc-shape-small, 4px));
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-SfQLQb-UbuQg-Bz112c {
  padding-left: 16px;
  padding-right: 0;
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc.VfPpkd-fmcmS-yrriRe-OWXEXe-SfQLQb-UbuQg-Bz112c {
  padding-right: 0;
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc.VfPpkd-fmcmS-yrriRe-OWXEXe-SfQLQb-UbuQg-Bz112c {
  padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
}

.VfPpkd-dgl2Hf-ppHlrf-sM5MNb {
  display: inline;
}

.Rvudpb {
  color: rgb(32, 33, 36);
  fill: rgb(32, 33, 36);
  stop-color: rgb(32, 33, 36);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0142857143em;
  line-height: 1.25rem;
}

.Rvudpb:not(:disabled) {
  color: rgb(32, 33, 36);
}

span {
  border: 0;
  color: inherit;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

.VfPpkd-NSFCdd-i5vt6e {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  text-align: left;
  pointer-events: none;
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc .VfPpkd-NSFCdd-i5vt6e {
  z-index: 1;
}

input {
  font-family: inherit;
  font-size: inherit;
}

input {
  background: none;
  border: 0;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: normal;
}

.VfPpkd-fmcmS-wGMbrd {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(
    --mdc-typography-subtitle1-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: 1rem;
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  -webkit-text-decoration: var(
    --mdc-typography-subtitle1-text-decoration,
    inherit
  );
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  height: 28px;
  transition: opacity 0.15s 0ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  min-width: 0;
  border: none;
  border-radius: 0;
  background: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
}

.VfPpkd-fmcmS-yrriRe .VfPpkd-fmcmS-wGMbrd {
  caret-color: #6200ee;
  caret-color: var(--mdc-theme-primary, #6200ee);
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc .VfPpkd-fmcmS-wGMbrd {
  height: 100%;
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc .VfPpkd-fmcmS-wGMbrd {
  display: flex;
  border: none !important;
  background-color: transparent;
}

.cfWmIb .VfPpkd-fmcmS-wGMbrd {
  font-family: Roboto, Arial, sans-serif;
  font-size: 1rem;
  letter-spacing: 0.00625em;
  font-weight: 400;
}

.cfWmIb .VfPpkd-fmcmS-wGMbrd {
  caret-color: rgb(26, 115, 232);
  caret-color: var(--gm-outlinedtextfield-caret-color, rgb(26, 115, 232));
}

.VfPpkd-fmcmS-yrriRe:not(.VfPpkd-fmcmS-yrriRe-OWXEXe-OWB6Me)
  .VfPpkd-fmcmS-wGMbrd {
  color: rgba(0, 0, 0, 0.87);
}

.cfWmIb:not(.VfPpkd-fmcmS-yrriRe-OWXEXe-OWB6Me) .VfPpkd-fmcmS-wGMbrd {
  color: rgb(60, 64, 67);
  color: var(--gm-outlinedtextfield-ink-color, rgb(60, 64, 67));
}

.RQQX4 {
  margin-left: 12px;
  margin-right: 8px;
}

.VfPpkd-LgbsSe {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  border: none;
  outline: none;
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
  background: transparent;
}

.VfPpkd-LgbsSe-OWXEXe-dgl2Hf {
  margin-top: 6px;
  margin-bottom: 6px;
}

.VfPpkd-LgbsSe {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
}

.VfPpkd-LgbsSe {
  padding: 0 8px 0 8px;
}

.VfPpkd-LgbsSe {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.VfPpkd-LgbsSe {
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0892857143em;
  font-weight: 500;
  text-transform: uppercase;
  height: 36px;
  border-radius: 4px;
}

.ksBjEc {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0107142857em;
  font-weight: 500;
  text-transform: none;
}

.VfPpkd-LgbsSe:not(:disabled) {
  color: #6200ee;
}

.ksBjEc:not(:disabled) {
  background-color: transparent;
}

.ksBjEc:not(:disabled) {
  color: rgb(26, 115, 232);
  color: var(--gm-colortextbutton-ink-color, rgb(26, 115, 232));
}

.Q7vmV .o1gy8c {
  width: 100%;
}

.u4ICaf .aLey0c:not(:disabled) {
  color: #01875f;
}

.VfPpkd-LgbsSe:hover {
  cursor: pointer;
}

.ksBjEc:hover:not(:disabled),
.ksBjEc:active:not(:disabled) {
  color: rgb(23, 78, 166);
  color: var(--gm-colortextbutton-ink-color--stateful, rgb(23, 78, 166));
}

.u4ICaf .aLey0c:not(:disabled):hover {
  color: #056449;
}

.VfPpkd-NSFCdd-Brv4Fb {
  box-sizing: border-box;
  height: 100%;
  pointer-events: none;
}

.VfPpkd-NSFCdd-Brv4Fb {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.VfPpkd-NSFCdd-Brv4Fb {
  border-left: 1px solid;
  border-right: none;
  width: 12px;
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc:not(.VfPpkd-fmcmS-yrriRe-OWXEXe-OWB6Me)
  .VfPpkd-NSFCdd-Brv4Fb {
  border-color: rgba(0, 0, 0, 0.38);
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-Brv4Fb {
  border-top-left-radius: 4px;
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-Brv4Fb {
  width: max(12px, var(--mdc-shape-small, 4px));
}

.cfWmIb:not(.VfPpkd-fmcmS-yrriRe-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-Brv4Fb {
  border-color: rgb(128, 134, 139);
  border-color: var(--gm-outlinedtextfield-outline-color, rgb(128, 134, 139));
}

.X8BSVc:not(.VfPpkd-fmcmS-yrriRe-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-Brv4Fb {
  border-color: rgb(218, 220, 224);
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc:not(.VfPpkd-fmcmS-yrriRe-OWXEXe-OWB6Me):not(
    .VfPpkd-fmcmS-yrriRe-OWXEXe-XpnDCe
  ):hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Brv4Fb {
  border-color: rgba(0, 0, 0, 0.87);
}

.cfWmIb:not(.VfPpkd-fmcmS-yrriRe-OWXEXe-OWB6Me):not(
    .VfPpkd-fmcmS-yrriRe-OWXEXe-XpnDCe
  ):hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Brv4Fb {
  border-color: rgb(32, 33, 36);
}

.X8BSVc:not(.VfPpkd-fmcmS-yrriRe-OWXEXe-OWB6Me):not(
    .VfPpkd-fmcmS-yrriRe-OWXEXe-XpnDCe
  ):hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-Brv4Fb {
  border-color: rgb(218, 220, 224);
}

.VfPpkd-NSFCdd-MpmGFe {
  box-sizing: border-box;
  height: 100%;
  pointer-events: none;
}

.VfPpkd-NSFCdd-MpmGFe {
  flex-grow: 1;
}

.VfPpkd-NSFCdd-MpmGFe {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.VfPpkd-NSFCdd-MpmGFe {
  border-left: none;
  border-right: 1px solid;
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc:not(.VfPpkd-fmcmS-yrriRe-OWXEXe-OWB6Me)
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgba(0, 0, 0, 0.38);
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-MpmGFe {
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: 4px;
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-left-radius: 0;
}

.cfWmIb:not(.VfPpkd-fmcmS-yrriRe-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgb(128, 134, 139);
  border-color: var(--gm-outlinedtextfield-outline-color, rgb(128, 134, 139));
}

.X8BSVc:not(.VfPpkd-fmcmS-yrriRe-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgb(218, 220, 224);
}

.VfPpkd-fmcmS-yrriRe-OWXEXe-INsAgc:not(.VfPpkd-fmcmS-yrriRe-OWXEXe-OWB6Me):not(
    .VfPpkd-fmcmS-yrriRe-OWXEXe-XpnDCe
  ):hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgba(0, 0, 0, 0.87);
}

.cfWmIb:not(.VfPpkd-fmcmS-yrriRe-OWXEXe-OWB6Me):not(
    .VfPpkd-fmcmS-yrriRe-OWXEXe-XpnDCe
  ):hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgb(32, 33, 36);
}

.X8BSVc:not(.VfPpkd-fmcmS-yrriRe-OWXEXe-OWB6Me):not(
    .VfPpkd-fmcmS-yrriRe-OWXEXe-XpnDCe
  ):hover
  .VfPpkd-NSFCdd-i5vt6e
  .VfPpkd-NSFCdd-MpmGFe {
  border-color: rgb(218, 220, 224);
}

.VfPpkd-Jh9lGc {
  position: absolute;
  box-sizing: content-box;
  overflow: hidden;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.VfPpkd-LgbsSe .VfPpkd-Jh9lGc {
  border-radius: 4px;
}

.ksBjEc .VfPpkd-Jh9lGc {
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
  z-index: 0;
}

.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}

.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::after {
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}

.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::before {
  background-color: #6200ee;
}

.ksBjEc .VfPpkd-Jh9lGc::before,
.ksBjEc .VfPpkd-Jh9lGc::after {
  background-color: rgb(26, 115, 232);
  background-color: var(--gm-colortextbutton-state-color, rgb(26, 115, 232));
}

.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::after {
  z-index: 0;
}

.VfPpkd-LgbsSe .VfPpkd-Jh9lGc::after {
  background-color: #6200ee;
}

.VfPpkd-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}

.ksBjEc:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
  transition: opacity 0.15s linear;
}

.VfPpkd-LgbsSe:hover .VfPpkd-Jh9lGc::before,
.VfPpkd-LgbsSe.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0.04;
}

.ksBjEc:hover .VfPpkd-Jh9lGc::before,
.ksBjEc.VfPpkd-ksKsZd-XxIAqe-OWXEXe-ZmdkE .VfPpkd-Jh9lGc::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.VfPpkd-LgbsSe .VfPpkd-J1Ukfc-LhBDec {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  display: none;
}

.VfPpkd-LgbsSe .VfPpkd-J1Ukfc-LhBDec {
  border-radius: 2px;
}

.LQeN7 .VfPpkd-J1Ukfc-LhBDec {
  pointer-events: none;
  border: 2px solid rgb(24, 90, 188);
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}

.VfPpkd-LgbsSe .VfPpkd-J1Ukfc-LhBDec::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}

.VfPpkd-LgbsSe .VfPpkd-J1Ukfc-LhBDec::after {
  border-radius: 4px;
}

.LQeN7 .VfPpkd-J1Ukfc-LhBDec::after {
  content: "";
  border: 2px solid rgb(232, 240, 254);
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}

.VfPpkd-LgbsSe .VfPpkd-RLmnJb {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.VfPpkd-LgbsSe .VfPpkd-kBDsod {
  margin-left: 0;
  margin-right: 8px;
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.VfPpkd-LgbsSe .VfPpkd-kBDsod {
  font-size: 1.125rem;
  width: 1.125rem;
  height: 1.125rem;
}

.Q7vmV .LFeKge {
  height: 24px;
  width: 24px;
}

.u4ICaf .aLey0c:not(:disabled) .VfPpkd-kBDsod {
  color: #01875f;
}

.u4ICaf .aLey0c:not(:disabled):hover .VfPpkd-kBDsod {
  color: #056449;
}

.VfPpkd-LgbsSe .VfPpkd-vQzf8d {
  position: relative;
}

.koWCib {
  color: #01875f;
  fill: #01875f;
  stop-color: #01875f;
}

.koWCib:not(:disabled) {
  color: #01875f;
}

.RQQX4 .o1gy8c {
  margin-top: 2px;
}

.VfPpkd-vQzf8d + .VfPpkd-kBDsod {
  margin-left: 8px;
  margin-right: 0;
}

.RQQX4 .LFeKge {
  height: 24px;
  width: 24px;
}

/* These were inline style tags. Uses id+class to override almost everything */
#style-JFfpX.style-JFfpX {
  --mdc-ripple-fg-size: 28px;
  --mdc-ripple-fg-scale: 1.7142857142857142;
  --mdc-ripple-left: 10px;
  --mdc-ripple-top: 10px;
}
